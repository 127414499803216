/* eslint-disable no-dupe-else-if */
import { log } from 'console';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Close from '../../../assets/Close.svg';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { useSideMenuContext } from '../../../context/useSideMenu';
import { DischargeSilo } from '../../../types/DischargeSilo';
import {
  IMouth,
  IMouthsWithStorageValueApp,
  MouthTank,
} from '../../../types/MouthTank';
import { Silo } from '../../../types/Silo';
import { formatDate } from '../../../utils/date/formatDate';
import { OptionSelecetInput } from '../../Inputs/OptionSelecetInput';
import { calculateRemainingValueForMouth } from './services';
import {
  ContainerOverlayModal,
  ContainerSelectMouths,
  ContentModal,
} from './styles';

export interface IModal {
  isOpen?: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
  silos: Silo[];
  createdDischargeCollectSilo: Silo;
  setCreatedDischargeCollectSilo: Dispatch<SetStateAction<Silo>>;
  handleCreateNewDischargeSilo: (silo: DischargeSilo) => void;
  volume: number | null;
  setVolume: Dispatch<SetStateAction<number | null>>;
  date: string;
  setDate: Dispatch<SetStateAction<string>>;
  hour: string;
  setHour: Dispatch<SetStateAction<string>>;
  DFIDCOLETA: string;
  tankMouths: MouthTank[];
  siloMouths: IMouth[];
  setSiloMouths: Dispatch<SetStateAction<IMouth[]>>;
  editing: boolean;
  handleEditDischargeSilo: (selectedSilo: DischargeSilo) => void;
  mouthsWithStorageValueApp: IMouthsWithStorageValueApp[];
  dischargeSilo: DischargeSilo[];
}

export const CreateNewSiloInCollectDischarge = ({
  closeModal,
  isOpen,
  createdDischargeCollectSilo,
  setCreatedDischargeCollectSilo,
  silos,
  handleCreateNewDischargeSilo,
  volume,
  setVolume,
  date,
  hour,
  setDate,
  setHour,
  DFIDCOLETA,
  tankMouths,
  siloMouths,
  setSiloMouths,
  editing,
  handleEditDischargeSilo,
  mouthsWithStorageValueApp,
  dischargeSilo,
}: IModal) => {
  const { sideMenuWidth } = useSideMenuContext();
  const { user } = useSigninContext();
  const [showContentModal, setShowContentModal] = useState(false);

  const verifyClose = (e: any) => {
    if (e.target.id === 'container-overlay-modal') {
      setCreatedDischargeCollectSilo({});
      closeModal(false);
      setVolume(null);
      setDate('');
      setHour('');
      const mouthsToShow = mouthsWithStorageValueApp.map(m => {
        return {
          DFBOCA: m.DFBOCA,
          DFVOLUME: '',
          DFDATE: moment(new Date()).format('yy-MM-DD'),
        };
      });
      setSiloMouths(mouthsToShow);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowContentModal(true);
      }, 0);
    } else {
      setTimeout(() => {
        setShowContentModal(false);
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    let total = 0;
    siloMouths.forEach(m => {
      total += Number(m.DFVOLUME);
    });
    setVolume(total);
  }, [JSON.stringify(siloMouths)]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (mouthsWithStorageValueApp.length > 0 && dischargeSilo.length > 0) {
      const mouthsToShow = mouthsWithStorageValueApp.map(m => {
        const volume = calculateRemainingValueForMouth(
          dischargeSilo,
          mouthsWithStorageValueApp,
          m.DFBOCA,
        );
        return {
          DFBOCA: m.DFBOCA,
          DFVOLUME: String(volume),
          DFDATE: moment(new Date()).format('yy-MM-DD'),
        };
      });
      setSiloMouths(mouthsToShow);
    } else if (mouthsWithStorageValueApp.length > 0) {
      const mouthsToShow = mouthsWithStorageValueApp.map(m => {
        return {
          DFBOCA: m.DFBOCA,
          DFVOLUME: m.DFVOLUMETOTAL,
          DFDATE: moment(new Date()).format('yy-MM-DD'),
        };
      });
      setSiloMouths(mouthsToShow);
    } else {
      const mouthsToShow = tankMouths.map(m => {
        return {
          DFBOCA: m.DFBOCA,
          DFVOLUME: '0',
          DFDATE: moment(new Date()).format('yy-MM-DD'),
        };
      });
      setSiloMouths(mouthsToShow);
    }
  }, [mouthsWithStorageValueApp, dischargeSilo, tankMouths]);

  const handleMouthChange = (
    DFBOCA: number,
    value: string,
    field: 'DFVOLUME' | 'DFDATE',
  ) => {
    setSiloMouths(prevMouths => {
      const existingMouth = prevMouths.find(mouth => mouth.DFBOCA === DFBOCA);
      if (existingMouth) {
        return prevMouths.map(mouth =>
          mouth.DFBOCA === DFBOCA ? { ...mouth, [field]: value } : mouth,
        );
      }
      return [
        ...prevMouths,
        {
          DFBOCA,
          DFVOLUME: field === 'DFVOLUME' ? value : '',
          DFDATE: field === 'DFDATE' ? value : '',
        },
      ];
    });
  };

  return (
    <ContainerOverlayModal
      isOpen={isOpen!}
      onClick={verifyClose}
      id="container-overlay-modal"
      width={sideMenuWidth}
    >
      <ContentModal isOpen={isOpen!} periodic={showContentModal}>
        <div className="close">
          <div className="close-button-selected-image">
            <img src={Close} onClick={() => closeModal(true)} />
          </div>
          <OptionSelecetInput
            {...{
              title: 'Selecionar silo',
              setCreatedDischargeCollectSilo,
              placeholder: 'Selecionar silo',
              silos,
              value: createdDischargeCollectSilo.DFDESCRICAOSILO,
            }}
          />
          {createdDischargeCollectSilo && (
            <div className="new-silo-content">
              <div className="row-inform-itens">
                <div className="inform-option" style={{ marginRight: 16 }}>
                  <label>Volume</label>
                  <input
                    value={volume || ''}
                    onChange={e => setVolume(Number(e.target.value))}
                    placeholder="Informar volume:"
                  />
                </div>
                <div className="inform-option" style={{ marginRight: 32 }}>
                  <label>Data</label>
                  <input
                    value={date || ''}
                    onChange={e => setDate(e.target.value)}
                    type="date"
                  />
                </div>
                <div className="inform-option">
                  <label>Hora</label>
                  <input
                    value={hour || ''}
                    onChange={e => setHour(e.target.value)}
                    type="time"
                  />
                </div>
              </div>
              {user && user.DFCONFIGURACAOSILOBOCA === 'S' && (
                <ContainerSelectMouths>
                  {siloMouths.map((mouth, i) => {
                    const { DFBOCA, DFDATE, DFVOLUME } = mouth;
                    return (
                      <div className="container-card-mouth" key={DFBOCA + i}>
                        <div className="card-mouth">
                          <label>Boca {DFBOCA}</label>
                          <input
                            placeholder="Quantidade"
                            value={DFVOLUME}
                            onChange={e =>
                              handleMouthChange(
                                DFBOCA,
                                e.target.value,
                                'DFVOLUME',
                              )
                            }
                          />
                        </div>
                        <div className="card-mouth" style={{ marginLeft: 10 }}>
                          <label>Data</label>
                          <input
                            placeholder="Data"
                            type="date"
                            value={DFDATE}
                            onChange={e =>
                              handleMouthChange(
                                DFBOCA,
                                e.target.value,
                                'DFDATE',
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </ContainerSelectMouths>
              )}
              <button
                onClick={() =>
                  editing
                    ? handleEditDischargeSilo({
                        silo: createdDischargeCollectSilo,
                        volume: volume!,
                        date,
                        hour,
                        DFIDCOLETA,
                        selectedSiloMouths: siloMouths,
                      })
                    : handleCreateNewDischargeSilo({
                        silo: createdDischargeCollectSilo,
                        volume: volume!,
                        date,
                        hour,
                        DFIDCOLETA,
                        selectedSiloMouths: siloMouths,
                      })
                }
              >
                {editing ? 'Salvar Alterações' : 'Criar Silo'}
              </button>
            </div>
          )}
        </div>
      </ContentModal>
    </ContainerOverlayModal>
  );
};
