import { IListCardsClosedCollect } from '../../../../types/ListCollectData';
import GeneralCollectCard from '../../../Cards/GeneralCollectCard';
import { ListCardCollects } from '../styles';

export const ListCardsClosedCollect = ({ data }: IListCardsClosedCollect) => {
  return (
    <ListCardCollects>
      {data.map((collect, key) => {
        return (
          <GeneralCollectCard collect={collect} type="encerrada" {...{ key }} />
        );
      })}
    </ListCardCollects>
  );
};
