import Switch from 'rc-switch';

interface ICustomSwitch {
  isOpen: boolean;
  action: () => void;
}

const CustomSwitch = ({ isOpen, action }: ICustomSwitch) => {
  return (
    <Switch
      className="rc-switch"
      style={{
        backgroundColor: `${isOpen ? '#3db866' : '#c73434'}`,
        animation: 'ease-in',
        boxShadow: 'none',
        borderColor: `${isOpen ? '#3db866' : '#c73434'}`,
        borderRadius: 50,
        alignContent: 'flex-end',
      }}
      onChange={() => action()}
      checked={isOpen}
    />
  );
};

export { CustomSwitch };
