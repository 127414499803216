import React, { useEffect, useState } from 'react';
import { LiaClipboardListSolid } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';

import { META_COLLECT_API } from '../../../api';
import { ReactComponent as Calendar } from '../../../assets/collectcard/calendar.svg';
import { ReactComponent as Line } from '../../../assets/collectcard/line.svg';
import { ReactComponent as Milk } from '../../../assets/collectcard/milk.svg';
import { ReactComponent as Vehicle } from '../../../assets/collectcard/vehicle.svg';
import { ReactComponent as Wagoner } from '../../../assets/collectcard/wagoner.svg';
import { ReactComponent as Info } from '../../../assets/info.svg';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { useCollectContext } from '../../../context/ProviderPage/Coleta';
import { useDischargeContext } from '../../../context/ProviderPage/Descarga';
import { useClosedCollectContext } from '../../../context/ProviderPage/Encerrada';
import { useExcludedCollectContext } from '../../../context/ProviderPage/Excluida';
import { useReleasedCollectContext } from '../../../context/ProviderPage/Liberar';
import { useScheduleContext } from '../../../context/ProviderPage/Programacao';
import { usePageContext } from '../../../context/usePage';
import { ClosedCollect } from '../../../types/ClosedCollect';
import { Collect } from '../../../types/Collect';
import { DischargeCollect } from '../../../types/DischargeCollect';
import { ExcludedCollect } from '../../../types/ExcludedCollect';
import { OpenCollect } from '../../../types/OpenCollect';
import { IGetPrtinClosedCollect } from '../../../types/PrintClosedCollect';
import { ReleasedCollect } from '../../../types/ReleasedCollect';
import { ScheduleCollect } from '../../../types/ScheduleCollect';
import { formatDate } from '../../../utils/date/formatDate';
import { returnTrueOrFalse } from '../../../utils/returnTrueOrFalse';
import { Counter } from '../../Contador';
import { CustomSwitch } from '../../CustomSwitch';
import { handleUpdateClosedCollect } from '../../List/ListClosedCollect/services';
import { CollectMilkInformation, GeneralCollectCardContainer } from './styles';

interface IGeneralCollectCard {
  collect: Collect;
  type:
    | 'programada'
    | 'aberta'
    | 'plataforma'
    | 'encerrada'
    | 'excluida'
    | 'liberada';
}

const GeneralCollectCard: React.FC<IGeneralCollectCard> = ({
  collect,
  type,
}) => {
  const navigation = useNavigate();
  const { version } = usePageContext();
  const [isPrinting, setIsPrinting] = useState(false);

  const {
    DFORDEMCOLETA,
    DFNOMECARRETEIRO,
    DFPLACAVEICULO,
    DFNOMELINHA,
    DFDATAPROGRAMADA,
    DFDATACOLETA,
    DFIDCOLETA,
  } = collect;

  const Scheduled = () => {
    const { setOpenInfo, setSelectedScheduleCollect } = useScheduleContext();
    const handleseelectedScheduleCollect = (collect: ScheduleCollect) => {
      setSelectedScheduleCollect(collect);
      setOpenInfo(true);
    };
    return (
      <GeneralCollectCardContainer>
        <div className="card-title">
          <Wagoner />
          <h1>{DFNOMECARRETEIRO}</h1>
        </div>
        <div className="collect-item-row">
          <div className="item-row-medium-with-border">
            <LiaClipboardListSolid />
            <span>Ordem Coleta: {DFORDEMCOLETA}</span>
          </div>
          <div className="item-row-medium">
            <Vehicle />
            <span>Veículo: {DFPLACAVEICULO}</span>
          </div>
        </div>
        <div className="collect-item-row">
          <div className="item-row-medium-with-border">
            <Line />
            <span>Linha: {DFNOMELINHA}</span>
          </div>
          <div className="item-row-medium-with-border">
            <Calendar />
            <span>Data Programada: {formatDate(DFDATAPROGRAMADA)}</span>
          </div>
          {/* <div className="item-row-medium">
      <Calendar />
      <span>Data Coleta: {formatDate(DFDATACOLETA)}</span>
    </div> */}
        </div>
        <footer className="card-footer">
          <button
            className="visualize"
            onClick={
              () => handleseelectedScheduleCollect(collect as any)
              // navigation(`/coleta/${DFIDCOLETA}/acompanhar${version}`)
            }
          >
            Visualizar
          </button>
          {/* <button
      className="close-collect"
      onClick={() =>
        setCloseCollect({
          isOpen: true,
          collect,
        })
      }
    >
      Encerrar
    </button>
    <button
      className="dicharge-collect"
      onClick={() =>
        setDischargeCollect({
          isOpen: true,
          collect,
          id: DFIDCOLETA,
        })
      }
    >
      Descarregar
    </button> */}
        </footer>
      </GeneralCollectCardContainer>
    );
  };

  const Platform = () => {
    const { DFTOTALARMAZENADO, DFCAPACIDADETOTALVEICULO, DFIDCOLETA } =
      collect as DischargeCollect;
    const FDFTOTALARMAZENADO = Number(DFTOTALARMAZENADO) || 0;
    const resultMeter =
      (FDFTOTALARMAZENADO / Number(DFCAPACIDADETOTALVEICULO)) * 100;
    const meter = resultMeter > 100 ? 100 : resultMeter;
    const { setSelectedDischargeCollect } = useDischargeContext();

    return (
      <GeneralCollectCardContainer>
        <div className="card-title">
          <Wagoner />
          <h1>{DFNOMECARRETEIRO}</h1>
        </div>
        <div className="collect-item-row">
          <div className="item-row-medium-with-border">
            <LiaClipboardListSolid />
            <span>Ordem Coleta: {DFORDEMCOLETA}</span>
          </div>
          <div className="item-row-medium">
            <Vehicle />
            <span>Veículo: {DFPLACAVEICULO}</span>
          </div>
        </div>
        <div className="collect-item-row">
          <div className="item-row-medium">
            <Line />
            <span>Linha: {DFNOMELINHA}</span>
          </div>
        </div>
        <div className="collect-item-row">
          <div className="item-row-medium-with-border">
            <Calendar />
            <span>Data Programada: {formatDate(DFDATAPROGRAMADA)}</span>
          </div>
          <div className="item-row-medium">
            <Calendar />
            <span>Data Coleta: {formatDate(DFDATACOLETA)}</span>
          </div>
        </div>
        <CollectMilkInformation {...{ meter, activeMeter: true }}>
          <div className="milk-title-information ">
            <Milk />
            <span>Quantidade coletada</span>
          </div>
          <div className="item-column">
            <div className="item-row">
              <span>
                <b>Coletado: </b>
                {/* <span>{FDFTOTALARMAZENADO}</span> */}
                <Counter finalNumber={Number(FDFTOTALARMAZENADO)} />
              </span>
              <span>
                <b>Total: </b>
                {/* <span>{DFCAPACIDADETOTALVEICULO}</span> */}
                <Counter finalNumber={Number(DFCAPACIDADETOTALVEICULO)} />
              </span>
            </div>
            <div className="progress-milk-content">
              <div className="progress-milk-meter" />
            </div>
          </div>
        </CollectMilkInformation>
        <footer className="card-footer">
          <button
            className="visualize"
            onClick={() =>
              navigation(`/plataforma/${DFIDCOLETA}/acompanhar${version}`)
            }
          >
            Acompanhar
          </button>
          <button
            className="dicharge-collect"
            onClick={() => {
              navigation(`/plataforma/${DFIDCOLETA}${version}`);
              setSelectedDischargeCollect(collect as DischargeCollect);
            }}
          >
            Descarregar
          </button>
        </footer>
      </GeneralCollectCardContainer>
    );
  };

  const Closed = () => {
    const {
      DFTOTALARMAZENADO,
      DFCAPACIDADETOTALVEICULO,
      DFIDCOLETA,
      DFBLOQUEIOERP,
    } = collect as DischargeCollect;
    const FDFTOTALARMAZENADO = Number(DFTOTALARMAZENADO) || 0;
    const resultMeter =
      (FDFTOTALARMAZENADO / Number(DFCAPACIDADETOTALVEICULO)) * 100;
    const meter = resultMeter > 100 ? 100 : resultMeter;
    const [isOpen, setIsOpen] = useState(returnTrueOrFalse(DFBLOQUEIOERP!));
    const {
      setSelectedClosedCollect,
      setOpenInfo,
      setPrintClosedCollect,
      printClosedCollect,
    } = useClosedCollectContext();
    const { path } = useSigninContext();

    const handleseelectedClosedCollect = (collect: ClosedCollect) => {
      setSelectedClosedCollect(collect);
      setOpenInfo(true);
    };

    const printElemente = (collect: ClosedCollect) => {
      if (isPrinting) return;
      setIsPrinting(true);
      setSelectedClosedCollect(collect);
      if (collect && collect.DFIDCOLETA && path) {
        META_COLLECT_API.get<IGetPrtinClosedCollect>(
          `/coleta/fechada/imprimir?DFIDCOLETA=${collect.DFIDCOLETA}`,
          { headers: { path } },
        )
          .then(({ data }) => {
            setPrintClosedCollect(data);
          })
          .catch(error => {
            console.error(error);
            alert('Ops, um erro inesperado aconteceu!');
            setIsPrinting(false);
          });
      }
    };

    useEffect(() => {
      if (printClosedCollect && isPrinting) {
        const screen = document.getElementById('template') as HTMLElement;
        const myWindow = window.open('', '_blank') as Window;

        if (myWindow && screen) {
          myWindow.document.write(
            '<html><head><title>Meta Collect</title></head><body>',
          );
          myWindow.document.write(screen.innerHTML);
          myWindow.document.write('</body></html>');
          myWindow.print();
          myWindow.close();
        }

        setIsPrinting(false);
      }
    }, [printClosedCollect]);

    return (
      <GeneralCollectCardContainer>
        <div className="card-title">
          <Wagoner />
          <h1>{DFNOMECARRETEIRO}</h1>
        </div>
        <div className="collect-item-row">
          <div className="item-row-medium-with-border">
            <LiaClipboardListSolid />
            <span>Ordem Coleta: {DFORDEMCOLETA}</span>
          </div>
          <div className="item-row-medium">
            <Vehicle />
            <span>Veículo: {DFPLACAVEICULO}</span>
          </div>
        </div>
        <div className="collect-item-row">
          <div className="item-row-medium">
            <Line />
            <span>Linha: {DFNOMELINHA}</span>
          </div>
        </div>
        <div className="collect-item-row">
          <div className="item-row-medium-with-border">
            <Calendar />
            <span>Data Programada: {formatDate(DFDATAPROGRAMADA)}</span>
          </div>
          <div className="item-row-medium">
            <Calendar />
            <span>Data Coleta: {formatDate(DFDATACOLETA)}</span>
          </div>
        </div>
        <CollectMilkInformation {...{ meter, activeMeter: true }}>
          <div className="milk-title-information ">
            <Milk />
            <span>Quantidade coletada</span>
          </div>
          <div className="item-column">
            <div className="item-row">
              <span>
                <b>Coletado: </b>
                {/* <span>{FDFTOTALARMAZENADO}</span> */}
                <Counter finalNumber={Number(FDFTOTALARMAZENADO)} />
              </span>
              <span>
                <b>Total: </b>
                {/* <span>{DFCAPACIDADETOTALVEICULO}</span> */}
                <Counter finalNumber={Number(DFCAPACIDADETOTALVEICULO)} />
              </span>
            </div>
            <div className="progress-milk-content">
              <div className="progress-milk-meter" />
            </div>
          </div>
        </CollectMilkInformation>
        <footer className="card-footer">
          <button
            className="visualize"
            onClick={() =>
              handleseelectedClosedCollect(collect as ClosedCollect)
            }
          >
            Visualizar
          </button>
          <button
            className="visualize"
            onClick={() =>
              navigation(`/plataforma/${DFIDCOLETA}/acompanhar${version}`)
            }
          >
            Acompanhar
          </button>
          <button
            className="dicharge-collect"
            onClick={() => printElemente(collect as DischargeCollect)}
          >
            Imprimir
          </button>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <CustomSwitch
              action={() =>
                handleUpdateClosedCollect({
                  collect,
                  setIsOpen,
                  isOpen,
                  path,
                })
              }
              isOpen={isOpen}
            />
          </div>
        </footer>
      </GeneralCollectCardContainer>
    );
  };

  const Excluded = () => {
    const { DFJUSTIFICATIVA, DFDATAEXCLUSAOCOLETA } =
      collect as unknown as ExcludedCollect;
    const { setOpenInfo, setSelectedExcludedCollect } =
      useExcludedCollectContext();
    const handleseelectedExcludedCollect = (collect: ExcludedCollect) => {
      setSelectedExcludedCollect(collect);
      setOpenInfo(true);
    };
    return (
      <GeneralCollectCardContainer>
        <div className="card-title">
          <Wagoner />
          <h1>{DFNOMECARRETEIRO}</h1>
        </div>
        <div className="collect-item-row">
          <div className="item-row-medium-with-border">
            <LiaClipboardListSolid />
            <span>Código: {DFIDCOLETA}</span>
          </div>
          <div className="item-row-medium">
            <Vehicle />
            <span>Veículo: {DFPLACAVEICULO}</span>
          </div>
        </div>
        <div className="collect-item-row">
          <div className="item-row-medium-with-border">
            <Line />
            <span>Linha: {DFNOMELINHA}</span>
          </div>
          <div className="item-row-medium-with-border">
            <Calendar />
            <span>Data Exclusão: {formatDate(DFDATAEXCLUSAOCOLETA)}</span>
          </div>
        </div>
        <div className="collect-item-row">
          <div className="item-row">
            <Info />
            <span>Justificativa: {DFJUSTIFICATIVA}</span>
          </div>
        </div>
        <footer className="card-footer">
          <button
            className="visualize"
            onClick={() => handleseelectedExcludedCollect(collect as any)}
          >
            Visualizar
          </button>
        </footer>
      </GeneralCollectCardContainer>
    );
  };

  const Released = () => {
    const { DFTOTALARMAZENADO, DFCAPACIDADETOTALVEICULO, DFIDCOLETA } =
      collect as DischargeCollect;
    const FDFTOTALARMAZENADO = Number(DFTOTALARMAZENADO) || 0;
    const resultMeter =
      (FDFTOTALARMAZENADO / Number(DFCAPACIDADETOTALVEICULO)) * 100;
    const meter = resultMeter > 100 ? 100 : resultMeter;

    return (
      <GeneralCollectCardContainer>
        <div className="card-title">
          <Wagoner />
          <h1>{DFNOMECARRETEIRO}</h1>
        </div>
        <div className="collect-item-row">
          <div className="item-row-medium-with-border">
            <LiaClipboardListSolid />
            <span>Ordem Coleta: {DFORDEMCOLETA}</span>
          </div>
          <div className="item-row-medium">
            <Vehicle />
            <span>Veículo: {DFPLACAVEICULO}</span>
          </div>
        </div>
        <div className="collect-item-row">
          <div className="item-row-medium">
            <Line />
            <span>Linha: {DFNOMELINHA}</span>
          </div>
        </div>
        <div className="collect-item-row">
          <div className="item-row-medium-with-border">
            <Calendar />
            <span>Data Programada: {formatDate(DFDATAPROGRAMADA)}</span>
          </div>
          <div className="item-row-medium">
            <Calendar />
            <span>Data Coleta: {formatDate(DFDATACOLETA)}</span>
          </div>
        </div>
        <CollectMilkInformation {...{ meter, activeMeter: true }}>
          <div className="milk-title-information ">
            <Milk />
            <span>Quantidade coletada</span>
          </div>
          <div className="item-column">
            <div className="item-row">
              <span>
                <b>Coletado: </b>
                {/* <span>{FDFTOTALARMAZENADO}</span> */}
                <Counter finalNumber={Number(FDFTOTALARMAZENADO)} />
              </span>
              <span>
                <b>Total: </b>
                {/* <span>{DFCAPACIDADETOTALVEICULO}</span> */}
                <Counter finalNumber={Number(DFCAPACIDADETOTALVEICULO)} />
              </span>
            </div>
            <div className="progress-milk-content">
              <div className="progress-milk-meter" />
            </div>
          </div>
        </CollectMilkInformation>
        <footer className="card-footer">
          <button
            className="visualize"
            onClick={() => navigation(`/liberar/${DFIDCOLETA}${version}`)}
          >
            Liberar
          </button>
        </footer>
      </GeneralCollectCardContainer>
    );
  };

  switch (type) {
    case 'programada':
      return <Scheduled />;
    case 'plataforma':
      return <Platform />;
    case 'encerrada':
      return <Closed />;
    case 'excluida':
      return <Excluded />;
    case 'liberada':
      return <Released />;
    default:
      return null;
  }
};

export default GeneralCollectCard;
