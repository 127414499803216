import { IListCardsReleasedCollect } from '../../../../types/ListCollectData';
import GeneralCollectCard from '../../../Cards/GeneralCollectCard';
import { ListCardCollects } from '../styles';

export const ListCardsReleasedCollect = ({
  data,
}: IListCardsReleasedCollect) => {
  return (
    <ListCardCollects>
      {data.map((collect, key) => {
        return (
          <GeneralCollectCard collect={collect} type="liberada" {...{ key }} />
        );
      })}
    </ListCardCollects>
  );
};
