import styled, { css } from 'styled-components';

export const ContainerPageHeader = styled.div`
  width: 100%;
  height: 70px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.COLORS.GREEN_100};
  /* -webkit-box-shadow: 0px 16px 21px -6px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 16px 21px -6px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 16px 21px -6px rgba(0, 0, 0, 0.15); */
  transition: 0.3s all;

  .modify-view-mode-container {
    margin-right: 0.7rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s all;
    padding-right: 0.45rem;
    border-right: 2px solid white;
  }

  .selected-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
      width: 25px;
    }
    h1 {
      margin-left: 1rem;
      font-size: 1.2rem;
      color: ${({ theme }) => theme.COLORS.WHITE};
      /* font-weight: bold; */
    }
  }
`;

interface IModifyViewModeOption {
  mode: 'card' | 'normal';
  active: 'card' | 'normal';
}

export const ModifyViewModeOption = styled.div<IModifyViewModeOption>`
  height: 40px;
  width: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  margin-left: 0.4rem;
  ${({ mode, active }) =>
    mode === active
      ? css`
          background-color: ${({ theme }) => theme.COLORS.GREEN_30};
          img {
            filter: brightness(1);
          }
        `
      : css`
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        `}

  img {
    width: 25px;
    height: 25px;
  }
`;
