import { ReactComponent as Image } from '../../assets/register-image.svg';
import { ReactComponent as Id } from '../../assets/TankItem/ID.svg';
import { ReactComponent as NormalTank } from '../../assets/TankItem/NormalTank.svg';
import { ReactComponent as IdMatricula } from '../../assets/TankItem/USER.svg';
import { TankAndProperty } from '../../types/TankAndProperty';
import { TankListOptionContainer } from './styles';

interface ITankItem {
  data: TankAndProperty;
  action: () => void;
}

const TankItem = ({ data, action }: ITankItem) => {
  const {
    DFDESCTANQUE,
    DFNOMEPRODUTOR,
    DFIDTANQUE,
    DFMATRICULA,
    DFTIPOITEMCOLETA,
    DFREGISTROS,
  } = data;

  const returnStatus = (
    DFTIPOITEMCOLETA: 'C' | 'N' | 'S',
    DFREGISTROS: number,
  ) => {
    if (DFREGISTROS >= 1) {
      return 'Não Coletado justificado';
    }
    switch (DFTIPOITEMCOLETA) {
      case 'C':
        return 'Coletado';
      case 'N':
        return 'Não Coletado';
      case 'S':
        return 'Socorro';
      default:
        return 'Sem definição';
    }
  };

  return (
    <TankListOptionContainer onClick={action} {...{ DFTIPOITEMCOLETA }}>
      <div className="row-tank">
        <NormalTank style={{ marginRight: 6 }} />
        <div className="column-tank">
          <span>{DFDESCTANQUE}</span>
          <span>{DFNOMEPRODUTOR}</span>
          <span>
            <b>Status: </b>
            {returnStatus(DFTIPOITEMCOLETA, DFREGISTROS)}
          </span>
        </div>
      </div>
      <div className="row">
        <div className="option">
          <Id />
          <span>{DFIDTANQUE}</span>
        </div>
        <div className="option">
          <IdMatricula />
          <span>{DFMATRICULA}</span>
        </div>
        <div className="option">
          <Image />
          <span>{DFREGISTROS}</span>
        </div>
      </div>
    </TankListOptionContainer>
  );
};

export { TankItem };
