import { IListCollectData } from '../../../../types/ListCollectData';
import { OpenCollectCard } from '../../../Cards/OpenCollectCard';
import { ListCardCollects } from '../styles';

export const ListCardOpenCollects = ({ data }: IListCollectData) => {
  return (
    <ListCardCollects>
      {data.map((collect, key) => {
        return <OpenCollectCard {...collect} {...{ key }} />;
      })}
    </ListCardCollects>
  );
};
