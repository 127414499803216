import { IListCardsExcludedCollect } from '../../../../types/ListCollectData';
import GeneralCollectCard from '../../../Cards/GeneralCollectCard';
import { ListCardCollects } from '../styles';

export const ListCardsExcludedCollect = ({
  data,
}: IListCardsExcludedCollect) => {
  return (
    <ListCardCollects>
      {data.map((collect, key) => {
        return (
          <GeneralCollectCard collect={collect} type="excluida" {...{ key }} />
        );
      })}
    </ListCardCollects>
  );
};
