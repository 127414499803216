import { IListCardsPlatformCollect } from '../../../../types/ListCollectData';
import GeneralCollectCard from '../../../Cards/GeneralCollectCard';
import { ListCardCollects } from '../styles';

export const ListCardsPlatformCollect = ({
  data,
}: IListCardsPlatformCollect) => {
  return (
    <ListCardCollects>
      {data.map((collect, key) => {
        return (
          <GeneralCollectCard
            collect={collect}
            type="plataforma"
            {...{ key }}
          />
        );
      })}
    </ListCardCollects>
  );
};
