import { useEffect, useState } from 'react';

import { DontExistsItem } from '../../../components/DontExistsItem';
import { DontExistsItemFlex } from '../../../components/DontExistsItem/styles';
import { ListCardsPlatformCollect } from '../../../components/List/Collects/ListCardsPlatformCollect';
import { ListDischargeCollects } from '../../../components/List/ListDischargeCollects';
import { LoadingData } from '../../../components/LoadingData';
import { InfoRegion } from '../../../components/Modal/InfoRegion';
import { PageHeader, ViewMode } from '../../../components/PageHeader';
import { Paginator } from '../../../components/Paginator';
import { useDischargeContext } from '../../../context/ProviderPage/Descarga';
import { usePageContext } from '../../../context/usePage';
import { ContainerPage, PageContent } from '../../../styles/global.styles';

const Descarga = () => {
  const { setSelectedPage } = usePageContext();
  const [modifyViewMode, setModifyViewMode] = useState<ViewMode>({
    have: true,
    mode: 'card',
  });
  const {
    loaddischargeAction,
    loadingDischargeCollect,
    dischargeCollect,
    openSideModalFilterDischargeCollectAction,
    openInfo,
    setOpenInfo,
    setOfsset,
    limit,
    offset,
    quantity,
    page,
    setPage,
    setHasClick,
    start,
    setStart,
  } = useDischargeContext();

  useEffect(() => {
    setSelectedPage({ option: 'descarga' });
    loaddischargeAction();
  }, []);

  return (
    <>
      <PageHeader
        {...{
          pageIcon: { option: 'descarga' },
          pageTitle: 'Plataforma',
          filterAction: openSideModalFilterDischargeCollectAction,
          modifyViewMode,
          setModifyViewMode,
        }}
      />

      <ContainerPage>
        <PageContent
          style={{
            justifyContent: `${
              loadingDischargeCollect === true ? 'center' : 'space-between'
            }`,
          }}
        >
          {loadingDischargeCollect ? (
            <LoadingData title="Buscando dados" />
          ) : (
            <>
              {dischargeCollect.length === 0 ? (
                <DontExistsItemFlex>
                  <DontExistsItem title="Nenhuma descarga foi encontrada!" />
                </DontExistsItemFlex>
              ) : (
                <>
                  {modifyViewMode.mode === 'normal' ? (
                    <ListDischargeCollects data={dischargeCollect} />
                  ) : (
                    <ListCardsPlatformCollect data={dischargeCollect} />
                  )}
                  <Paginator
                    {...{
                      start,
                      setStart,
                      limitItens: limit,
                      offset,
                      page,
                      quantity,
                      setHasClick,
                      setOfsset,
                      setPage,
                      quantityShowed: dischargeCollect.length,
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageContent>
        <InfoRegion
          option="coleta"
          titleModal="Coleta selecionada"
          isOpen={openInfo}
          closeModal={setOpenInfo}
        />
      </ContainerPage>
    </>
  );
};

export { Descarga };
