export default {
  COLORS: {
    WHITE: '#FFFFFF',
    GREEN_30: '#52aab2',
    GREEN_20: '#99CCCC',
    GREEN_100: '#037782',
    GREEN_DARK: '#037782',
    // GREEN_100: '#0B3118',
    // GREEN_DARK: '#0B3118',
    GREY: '#454545',
    GREY_2: '#EEEEEE',
    GREY_10: '#CCC',
    GREY_50: '#BAB8B8',
    BLACK_1000: '#000',
    CARD_1: '#18A748',
    CARD_2: '#CAB920',
    CARD_3: '#CA2020',
    CARD_4: '#6B20CA',
    CARD_5: '#CA8322',
    IC: '#6B20CA', // INICIO DE COLETA
    CF: '#3A4CEE', // COLETA FINALIZADA(COLETA COMO UM TODO)
    DC: '#CA8322', // DESCARGA DE COLETA
    CP: '#18A748', // COLETA EM PROPRIEDADE
    NC: '#CA2020', // NÃO COLETADA
    CS: '#CAB920', // COLETA SOCORRO
    NI: '#BAB8B8',
    PP: '#964b00',
    OI: '#0F5431', // ODOMETRO INICIO
    OF: '#0F5431 ', // ODOMETRO FIM
    METER_40: '#18AF7D',
    METER_41: '#FFC85A',
    METER_66: '#FF5A5A',
  },
};
