/* eslint-disable no-promise-executor-return */
/* eslint-disable no-return-assign */
/* eslint-disable new-cap */
/* eslint-disable no-new */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { META_COLLECT_API } from '../../../api';
import { useSigninContext } from '../../../context/AuthProviderPages/Signin';
import { useClosedCollectContext } from '../../../context/ProviderPage/Encerrada';
import { usePageContext } from '../../../context/usePage';
import { ClosedCollect } from '../../../types/ClosedCollect';
import { IListCollectData } from '../../../types/ListCollectData';
import { IGetPrtinClosedCollect } from '../../../types/PrintClosedCollect';
import { formatDate } from '../../../utils/date/formatDate';
import { returnTrueOrFalse } from '../../../utils/returnTrueOrFalse';
import { ListButton } from '../../Buttons/ListButton';
import { CustomSwitch } from '../../CustomSwitch';
import { AnimateTr, ContainerListPage } from '../styles';
import { handleUpdateClosedCollect } from './services';
// ... outras importações

export const ListClosedCollects = ({ data }: IListCollectData) => {
  const {
    setOpenInfo,
    setSelectedClosedCollect,
    setOpenFirstModel,
    setOpenSecondModel,
    setPrintClosedCollect,
    printClosedCollect,
  } = useClosedCollectContext();
  const { path } = useSigninContext();
  const { version } = usePageContext();

  const [isPrinting, setIsPrinting] = useState(false);

  const handleseelectedClosedCollect = (collect: ClosedCollect) => {
    setSelectedClosedCollect(collect);
    setOpenInfo(true);
  };

  function carregarImagemEConverterParaBase64(
    caminho: string,
  ): Promise<string | null> {
    return new Promise(resolve => {
      fetch(caminho)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result;
            if (base64String) {
              resolve(base64String.toString());
            }
          };
          reader.readAsDataURL(blob);
        })
        .catch(error =>
          console.error(() => {
            resolve(null);
          }),
        );
    });
  }

  // const printElemente = (collect: ClosedCollect) => {
  //   if (isPrinting) return;

  //   setIsPrinting(true);
  //   try {
  //     setSelectedClosedCollect(collect);
  //     if (collect && collect.DFIDCOLETA && path) {
  //       META_COLLECT_API.get<IGetPrtinClosedCollect>(
  //         `/coleta/fechada/imprimir?DFIDCOLETA=${collect.DFIDCOLETA}`,
  //         {
  //           headers: { path },
  //         },
  //       ).then(({ data }) => {
  //         setPrintClosedCollect(data);
  //         const screen = document.getElementById('template') as any;
  //         const myWindow = window.open('', '_blank') as any;
  //         myWindow.document.write(
  //           '<html><head><title>Meta Collect</title></head><body>',
  //         );
  //         myWindow.document.write(screen.innerHTML);
  //         myWindow.document.write('</body></html>');
  //         myWindow.print();
  //         myWindow.close();
  //         setIsPrinting(false);
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     alert('Ops, um erro inesperado aconteceu!');
  //     setIsPrinting(false);
  //   }
  // };

  const printElemente = (collect: ClosedCollect) => {
    if (isPrinting) return;
    setIsPrinting(true);
    setSelectedClosedCollect(collect);
    if (collect && collect.DFIDCOLETA && path) {
      META_COLLECT_API.get<IGetPrtinClosedCollect>(
        `/coleta/fechada/imprimir?DFIDCOLETA=${collect.DFIDCOLETA}`,
        { headers: { path } },
      )
        .then(({ data }) => {
          setPrintClosedCollect(data);
        })
        .catch(error => {
          console.error(error);
          alert('Ops, um erro inesperado aconteceu!');
          setIsPrinting(false);
        });
    }
  };

  useEffect(() => {
    if (printClosedCollect && isPrinting) {
      const screen = document.getElementById('template') as HTMLElement;
      const myWindow = window.open('', '_blank') as Window;

      if (myWindow && screen) {
        myWindow.document.write(
          '<html><head><title>Meta Collect</title></head><body>',
        );
        myWindow.document.write(screen.innerHTML);
        myWindow.document.write('</body></html>');
        myWindow.print();
        myWindow.close();
      }

      setIsPrinting(false);
    }
  }, [printClosedCollect]);

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Ordem Coleta</th>
            <th className="list-th">Encerrada</th>
            <th className="list-th">Carreteiro</th>
            <th className="list-th">Total em litros</th>
            <th className="list-th">Veículo</th>
            <th className="list-th">Linha</th>
            <th className="list-th">Data Programada</th>
            <th className="list-th">Data Saída</th>
            <th className="list-th">Data Coleta</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((collect, index) => {
            const {
              DFDATACOLETA,
              DFDATASAIDA,
              DFIDCOLETA,
              DFNOMECARRETEIRO,
              DFNOMELINHA,
              DFORDEMCOLETA,
              DFPLACAVEICULO,
              DFBLOQUEIOERP,
              DFTOTALARMAZENADO,
              DFDATAPROGRAMADA,
            } = collect;

            const [isOpen, setIsOpen] = useState(
              returnTrueOrFalse(DFBLOQUEIOERP!),
            );
            return (
              <AnimateTr key={index} index={index}>
                <td className="list-td-small">
                  {DFORDEMCOLETA || 'Não informado'}
                </td>
                <td className="list-td-small">
                  <CustomSwitch
                    action={() =>
                      handleUpdateClosedCollect({
                        collect,
                        setIsOpen,
                        isOpen,
                        path,
                      })
                    }
                    isOpen={isOpen}
                  />
                </td>
                <td className="list-td-large">
                  {DFNOMECARRETEIRO || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFTOTALARMAZENADO || 'Não informado'}
                </td>
                <td className="list-td-small">
                  {DFPLACAVEICULO || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFNOMELINHA || 'Não informado'}
                </td>
                <td className="list-td-medium">
                  {formatDate(DFDATAPROGRAMADA)}
                </td>
                <td className="list-td-small">{formatDate(DFDATASAIDA)}</td>
                <td className="list-td-small">{formatDate(DFDATACOLETA)}</td>
                <td className="list-td-small">
                  <div>
                    <Link to={`/encerrada/${DFIDCOLETA}/acompanhar${version}`}>
                      <ListButton
                        {...{
                          action: () => null,
                          icon: 'arrown-down',
                        }}
                      />
                    </Link>
                    <ListButton
                      {...{
                        action: () => handleseelectedClosedCollect(collect),
                        icon: 'search',
                      }}
                    />
                    <ListButton
                      {...{
                        action: () => printElemente(collect),
                        icon: 'print',
                        disabled: isPrinting,
                      }}
                    />
                  </div>
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
