/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { useNavigate } from 'react-router-dom';

import { useTankToReleaseContextProvider } from '../../../context/ProviderPage/Vincular';
import { usePageContext } from '../../../context/usePage';
import { TankRelease } from '../../../types/TankRelease';
import { returnCheckType } from '../../../utils/returnCheckType';
import { ListButton } from '../../Buttons/ListButton';
import { AnimateTr, ContainerListPage } from '../styles';

interface IListTanks {
  data: TankRelease[];
}

export const ListTanksToRelease = ({ data }: IListTanks) => {
  const navigate = useNavigate();
  const { setSelectedTankToRelease } = useTankToReleaseContextProvider();
  const { version } = usePageContext();

  const verifyClose = (e: any, tank: TankRelease) => {
    if (e.currentTarget.id !== 'select-tank') {
      setSelectedTankToRelease(tank);
      navigate(`/vincular/${tank.DFIDTANQUE}/armazenamento${version}`);
      return false;
    }
  };

  return (
    <ContainerListPage>
      <table>
        <thead>
          <tr>
            <th className="first-table-th">Ativo</th>
            <th className="list-th">Coleta Seletiva</th>
            <th className="list-th">Código</th>
            <th className="list-th">Descrição</th>
            <th className="list-th">Linha</th>
            <th className="last-table-th">Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map((tank, index) => {
            const {
              DFATIVO,
              DFCOLETASELETIVA,
              DFDESCTANQUE,
              DFIDTANQUE,
              DFNOMELINHA,
            } = tank;
            return (
              <AnimateTr
                key={index}
                index={index}
                onClick={e => verifyClose(e, tank)}
              >
                <td className="list-td-small">
                  <img className="icon-check" src={returnCheckType(DFATIVO!)} />
                </td>
                <td className="list-td-small">
                  <img
                    className="icon-check"
                    src={returnCheckType(DFCOLETASELETIVA!)}
                  />
                </td>
                <td className="list-td-small">
                  {DFIDTANQUE || 'Não informado'}
                </td>
                <td className="list-td-large">
                  {DFDESCTANQUE || 'Não informado'}
                </td>

                <td className="list-td-large">
                  {DFNOMELINHA || 'Não informado'}
                </td>
                <td className="list-td-small">
                  <div>
                    <ListButton
                      id="select-tank"
                      {...{
                        action: (e: any) => verifyClose(e, tank),
                        icon: 'arrown-down',
                      }}
                    />
                  </div>
                </td>
              </AnimateTr>
            );
          })}
        </tbody>
      </table>
    </ContainerListPage>
  );
};
