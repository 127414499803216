/* eslint-disable no-param-reassign */
const formatInputNumber = (value: string) => {
  value = value.replace(/\D/g, '');
  return value;
};

const formatInputNumberWithoutZero = (value: string): string => {
  const num = Math.max(0, Number(value.replace(/\D/g, '')));
  return String(num);
};

export { formatInputNumber, formatInputNumberWithoutZero };
