/* eslint-disable @typescript-eslint/no-unused-vars */

import { Dispatch, ReactNode, SetStateAction } from 'react';

import ListCard from '../../assets/list-card.svg';
import ListNormal from '../../assets/list-normal.svg';
import { Option } from '../../types/Option';
import { returnIcon } from '../../utils/returnIcon';
import { FilterButton } from '../Buttons/FilterButton';
import { ContainerPageHeader, ModifyViewModeOption } from './styles';

export type ViewMode = {
  have: boolean;
  mode: 'normal' | 'card';
};

interface IPageHeader {
  pageIcon: Option;
  pageTitle: string;
  filterAction?: () => void;
  hasFilter?: boolean;
  button?: ReactNode;
  modifyViewMode?: ViewMode;
  setModifyViewMode?: Dispatch<SetStateAction<ViewMode>>;
}

const PageHeader = ({
  pageIcon,
  pageTitle,
  filterAction,
  hasFilter = true,
  button,
  modifyViewMode,
  setModifyViewMode,
}: IPageHeader) => {
  return (
    <ContainerPageHeader>
      <div className="selected-page">
        <img src={returnIcon({ option: pageIcon.option })} />
        <h1>{pageTitle}</h1>
      </div>
      <div className="row">
        {modifyViewMode && setModifyViewMode && (
          <div className="modify-view-mode-container">
            <ModifyViewModeOption
              active={modifyViewMode.mode}
              mode="card"
              onClick={() =>
                setModifyViewMode({
                  have: true,
                  mode: 'card',
                })
              }
            >
              <img src={ListCard} />
            </ModifyViewModeOption>
            <ModifyViewModeOption
              active={modifyViewMode.mode}
              mode="normal"
              onClick={() =>
                setModifyViewMode({
                  have: true,
                  mode: 'normal',
                })
              }
            >
              <img src={ListNormal} />
            </ModifyViewModeOption>
          </div>
        )}
        {!hasFilter ? (
          <div style={{ height: 50 }} />
        ) : (
          <FilterButton width={130} action={filterAction} />
        )}
        {button && button}
      </div>
    </ContainerPageHeader>
  );
};

export { PageHeader };
